<template>
  <div class="admin-edit-shop-product" v-if="!loading">
    <div class="row">
      <div class=" col s4">
        <label for="user_name" class="device-label">Product Name</label>
        <input type="text" v-model="productInternal.name" class="text-field w-input" disabled
               maxlength="256" placeholder="Full Name" id="user_name">
      </div>
      <div class="col-4">
        <label for="product_id" class="device-label">Shop Product Id</label>
        <input type="text" v-model="productInternal.shop_product_id" class="text-field w-input" disabled
               maxlength="256" name="Registration" data-name="Registration" placeholder="Stripe Product" id="product_id">
      </div>
      <div class="spacing"></div>
    </div>
    <div class="coupon-container">
      <div class="row">
        <label for="product_id" class="device-label">Coupon Codes</label>
        <i class="icon-button" :class="$config.icons.general.add" v-b-tooltip title="Add" @click="addCoupon"></i>
      </div>
      <div v-for="(coupon, idx) of coupons" v-bind:key="idx" class="coupon-item">
        <b-form-select v-model="coupons[idx]" :options="couponOptions" placeholder="Coupon Code" class="text-input w-input"></b-form-select>
<!--            <input type="text" v-model="coupons[idx]" class="text-field w-input"-->
<!--                   maxlength="256" placeholder="Coupon Code">-->
        <i class="fa fa-times-circle row-icon-button-danger row-action ml-3" v-b-tooltip.hover title="Delete"
           @click="deleteCoupon(coupon, idx)"></i>
      </div>
    </div>
    <div class="row">
      <div class="col s12">
        Last Updated: {{productInternal.modified_datetime}}
      </div>
    </div>
    <div class="footer mt-2">
      <b-button class="button" @click="clickClose()">Close</b-button>
      <b-button class="button" @click="saveChanges()">Save Changes</b-button>
    </div>
  </div>
</template>

<script>
import * as DataProvider from '../helpers/DataProvider'
import * as ErrorHelper from '../helpers/ErrorHelper'
// import * as AlertHelper from '../helpers/AlertHelper'

export default {
  name: 'admin-edit-shop-product',
  components: {
  },
  props: {
    isModal: Boolean,
    modal: String,
    product: Object,
    shopProductId: String
  },
  data: function () {
    return {
      loading: true,
      showButtons: true,
      selectedDevices: null,
      productInternal: {
        id: null,
        name: 'Some Product',
        shop_product_id: '',
        modified_datetime: ''
      },
      couponData: [],
      couponOptions: [{
        value: '',
        text: ''
      }],
      coupons: []
    }
  },
  async created () {
    if (this.product) {
      console.log(this.product)
      this.loadProduct(this.product)
    } else if (this.shopProductId) {
      let res = await DataProvider.adminGetShopProduct(this.shopProductId)
      if (res.success) {
        this.productInternal = res.data
        this.loadProduct(this.productInternal)
      } else {
        ErrorHelper.displayDataErrorToast(res)
      }
    } else {
      this.productInternal = {
        selected: false,
        userId: '-1',
        devices: [],
        notifications: [],
        email: '',
        mobile: '',
        name: '',
        activated: false,
        admin: false,
        roles: [],
        lastLogin: ''
      }
    }
    await this.loadCoupons()
    // Hide Controls so the parent modal can display them
    if (this.isModal) {
      this.showButtons = false
    }
    this.loading = false
  },
  methods: {
    loadProduct: function (productData) {
      this.productInternal = { ...productData } // Take a shallow copy to avoid editing the parent component's data directly
      // Note: If we don't at least take a shallow copy we can end up with unexpected behavior such as unsaved changes
      // being visible on the parent component, since JS passes by reference.
      if (this.productInternal.product_actions.coupons) {
        this.coupons = this.productInternal.product_actions.coupons
      } else {
        this.coupons = []
      }
    },
    loadCoupons: async function () {
      let couponData = await DataProvider.adminGetCoupons()
      if (couponData.success) {
        this.couponData = couponData.data.filter(x => x.valid)
        this.couponOptions = []
        this.couponData.forEach(coupon => {
          this.couponOptions.push({
            value: coupon.id,
            text: `${coupon.id} - ${coupon.name} - $${coupon.amount_off} / ${coupon.percent_off}%`
          })
        })
      } else {
        ErrorHelper.displayDataErrorToast(couponData)
      }
    },
    saveChanges: async function () {
      let res
      let coupons = Array.from(new Set(this.coupons.filter(x => x !== ''))) // Remove blank Items, use Set to remove dupes
      console.log(coupons)
      res = await DataProvider.adminUpdateShopProduct(
        this.productInternal.id, { coupons })
      if (res.success) {
        console.log(res)
        this.loadProduct(res.data)
        this.$bvToast.toast('The changes you made have been saved!',
          {
            title: 'Changes Saved',
            variant: 'success',
            toaster: 'b-toaster-top-center'
          })
      } else {
        ErrorHelper.displayDataErrorToast(res)
      }
    },
    clickClose: async function () {
      console.log(this.productInternal)
      this.$emit('save')
      // Check if we've been passed a modal to close.
      if (this.modal) {
        this.$bvModal.hide(this.modal)
      } else {
        this.$router.go(-1)
      }
    },
    changeSelection: function (newVal) {
      this.selectedDevices = newVal
    },
    addCoupon: function () {
      this.coupons.push('')
    },
    deleteCoupon: function (coupon, idx) {
      this.coupons.splice(idx, 1)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../../variables';

  .admin-edit-shop-product {
    color: $text-color-invert;
  }

  .device-label{
    font-family: 'Open Sans', sans-serif;
    color: $theme-color-primary-3;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
  }

  .row {
    margin: 0;
  }

  .device-code{
    color: $text-color-invert;
    margin-left: 5%;
  }

  .toolbar {
    display: flex;
    flex-direction: row;
  }

  .icon-button, .icon-button-danger{
    font-size: 1.9rem;
  }

  .footer{
    bottom: 2%;
    display: flex;
    justify-content: flex-end;
    margin: 0 1% 0 1%;
  }

  .icon_select_color {
    border: solid $theme-color-primary-3;
  }

  .coupon-container {
    display: flex;
    flex-direction: column;
  }

  .coupon-item {
    display: flex;
    align-items: center;
    width: 50%;
    background: $theme-color-background-1;
    margin: 5px 10px;
    padding: 5px;
    border-radius: 10px;
  }

</style>
