var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c("div", { staticClass: "admin-edit-shop-product" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col s4" }, [
            _c(
              "label",
              { staticClass: "device-label", attrs: { for: "user_name" } },
              [_vm._v("Product Name")]
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.productInternal.name,
                  expression: "productInternal.name"
                }
              ],
              staticClass: "text-field w-input",
              attrs: {
                type: "text",
                disabled: "",
                maxlength: "256",
                placeholder: "Full Name",
                id: "user_name"
              },
              domProps: { value: _vm.productInternal.name },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.productInternal, "name", $event.target.value)
                }
              }
            })
          ]),
          _c("div", { staticClass: "col-4" }, [
            _c(
              "label",
              { staticClass: "device-label", attrs: { for: "product_id" } },
              [_vm._v("Shop Product Id")]
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.productInternal.shop_product_id,
                  expression: "productInternal.shop_product_id"
                }
              ],
              staticClass: "text-field w-input",
              attrs: {
                type: "text",
                disabled: "",
                maxlength: "256",
                name: "Registration",
                "data-name": "Registration",
                placeholder: "Stripe Product",
                id: "product_id"
              },
              domProps: { value: _vm.productInternal.shop_product_id },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.productInternal,
                    "shop_product_id",
                    $event.target.value
                  )
                }
              }
            })
          ]),
          _c("div", { staticClass: "spacing" })
        ]),
        _c(
          "div",
          { staticClass: "coupon-container" },
          [
            _c("div", { staticClass: "row" }, [
              _c(
                "label",
                { staticClass: "device-label", attrs: { for: "product_id" } },
                [_vm._v("Coupon Codes")]
              ),
              _c("i", {
                directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
                staticClass: "icon-button",
                class: _vm.$config.icons.general.add,
                attrs: { title: "Add" },
                on: { click: _vm.addCoupon }
              })
            ]),
            _vm._l(_vm.coupons, function(coupon, idx) {
              return _c(
                "div",
                { key: idx, staticClass: "coupon-item" },
                [
                  _c("b-form-select", {
                    staticClass: "text-input w-input",
                    attrs: {
                      options: _vm.couponOptions,
                      placeholder: "Coupon Code"
                    },
                    model: {
                      value: _vm.coupons[idx],
                      callback: function($$v) {
                        _vm.$set(_vm.coupons, idx, $$v)
                      },
                      expression: "coupons[idx]"
                    }
                  }),
                  _c("i", {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover",
                        modifiers: { hover: true }
                      }
                    ],
                    staticClass:
                      "fa fa-times-circle row-icon-button-danger row-action ml-3",
                    attrs: { title: "Delete" },
                    on: {
                      click: function($event) {
                        return _vm.deleteCoupon(coupon, idx)
                      }
                    }
                  })
                ],
                1
              )
            })
          ],
          2
        ),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col s12" }, [
            _vm._v(
              " Last Updated: " +
                _vm._s(_vm.productInternal.modified_datetime) +
                " "
            )
          ])
        ]),
        _c(
          "div",
          { staticClass: "footer mt-2" },
          [
            _c(
              "b-button",
              {
                staticClass: "button",
                on: {
                  click: function($event) {
                    return _vm.clickClose()
                  }
                }
              },
              [_vm._v("Close")]
            ),
            _c(
              "b-button",
              {
                staticClass: "button",
                on: {
                  click: function($event) {
                    return _vm.saveChanges()
                  }
                }
              },
              [_vm._v("Save Changes")]
            )
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }